import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import Layout from '../components/layout'
import contact_items from '../data/contact_items.json';
import { useForm, ValidationError } from '@formspree/react';
import Seo from '../components/seo';
import { isAndroid, isIOS } from 'react-device-detect';
import { StaticImage } from 'gatsby-plugin-image';
import logo from '../images/favicon/tf_round_blue.png'

export default function QSafe({ location }) {
    const query = new URLSearchParams(location.search);
    const id = query.get('id') || '';
    const link = `https://portal.timefree.ph/qsafe/clientb.aspx?q=${id}`;

    useEffect(() => {
        // console.log(link);
        setTimeout(() => {
            window.location.href = link;
        }, 2000);
    })
    return (
        <Layout location="/qsafe">
            {/* <> */}
            <Seo title="QSafe Redirect" />
            <div className="flex flex-col flex-wrap content-center justify-center h-full w-screen text-center px-10">
                {/* <StaticImage
                    className="max-h-20 w-auto object-contain"
                    src={logo}
                    placeholder="tracedSVG"
                    alt=""
                /> */}
                <h1 className="font-black text-gray-700 mt-1 text-4xl">QSafe</h1>
                <p className="font-normal text-gray-400 mt-2 text-lg">You are being redirected to the QSafe portal.</p>
                <div>
                    <Link to={link} className="font-normal text-darkblue-tf mt-4 text-lg  flex content-center flex-wrap justify-center gap-x-2">Click here if you are not redirectly in 10 seconds
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-darkblue-tf " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                    </Link>
                </div>
            </div>
            {/* </> */}
        </Layout>
    )
}
